<template>
  <div>
    <b-row>
      <b-col>
        <datatable @show-filters-component="showFiltersComponent()" ref="concessoesDatatable"
          @changeSelectedRows="changeSelectedRows" show-export-button :xlsxExportAction="generateXlsxReport">
          <template #table-caption>
            <div class="d-flex justify-content-start align-items-center">
              <b-form-checkbox class="mr-3" @change="$refs.concessoesDatatable.toggleSelectedAllRows($event)">
                Selecionar todos
              </b-form-checkbox>
              <v-select
                v-model="selectedAction"
                :options="actionsForSelectedItems"
                label="label"
                :clearable="false"
                @input="performSelectedAction"
                class="custom-selector d-flex flex-column"
              >
              <span slot="no-options">Nenhuma opção disponível</span>
              </v-select>
            </div>
          </template>
        </datatable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BModal,
  BRow,
  BTable,
  BBadge,
  BFormCheckbox,
  BTooltip,
} from "bootstrap-vue";
import Datatable from "@/views/common/crud/components/Datatable";
import { downloadURI } from "@/@core/comp-functions/forms/cc-dropzone";
import vSelect from 'vue-select';

export default {
  name: "Concessoes",
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    Datatable,
    BModal,
    BButton,
    BBadge,
    BFormCheckbox,
    BTooltip,
    vSelect,
  },
  data() {
    return {
      selectedRows: [],
      selectedAction: "Ações",
    };
  },
  methods: {
    changeSelectedRows(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async downloadCnabFileRequest() {
      const { data } = await this.$store.dispatch(
        "concession/generateCnabFileForConcessions",
        this.selectedRowsIds
      );
      const url = window.URL.createObjectURL(data);
      downloadURI(url);
    },
    async downloadCnabFile() {
      try {
        if (this.selectedRowsIds.length > 1) {
          await this.$bvModal
            .msgBoxConfirm(
              `Você deseja baixar o CNAB referente à todas as concessões selecionadas?\n\nSelecionadas: ${this.selectedRows.length}`,
              {
                title: "Baixar CNAB",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Baixar todos",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) await this.downloadCnabFileRequest();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          await this.downloadCnabFileRequest();
        }
      } catch (error) {
        console.log(error);
      }
      this.selectedAction = "Ações";
    },
    async firstDepositRequest() {
      this.selectedRowsIds.map(
        async (id) => await this.$store.dispatch("concession/makeDeposit", id)
      );
    },
    async ncSetDueRequest() {
      await this.$store.dispatch('concession/postNcSetDue', { id: this.selectedRowsIds[0] })
    },
    async markFirstDeposit() {
      try {
        if (this.selectedRowsIds.length > 1) {
          await this.$bvModal
            .msgBoxConfirm(
              `Você deseja comunicar que o depósito referente à todas as concessões selecionadas foi realizado?\n\nSelecionadas: ${this.selectedRows.length}`,
              {
                title: "Informar 1º depósito realizado",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Sim",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                await this.firstDepositRequest().then(async (response) => {
                  await this.$bvModal
                    .msgBoxOk("Informe de 1° depósito realizado com sucesso!", {
                      title: "Informar 1º depósito realizado",
                      size: "sm",
                      buttonSize: "md",
                      okVariant: "primary",
                      footerClass:
                        "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                      headerClass: "p-2",
                      titleClass:
                        "d-flex align-items-center justify-content-center text-primary w-100",
                      headerBgVariant: "white",
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(async (isConfirmed) => {
                      if (isConfirmed) {
                        await this.$nextTick(() => {
                          this.$refs.concessoesDatatable.refresh();
                        });
                      }
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          await this.$bvModal
            .msgBoxConfirm(
              "Você deseja comunicar que o depósito referente à concessão selecionada foi realizado?",
              {
                title: "Informar 1º depósito realizado",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Sim",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                await this.firstDepositRequest().then(async (response) => {
                  await this.$bvModal
                    .msgBoxOk("Informe de 1° depósito realizado com sucesso!", {
                      title: "Informar 1º depósito realizado",
                      size: "sm",
                      buttonSize: "md",
                      okVariant: "primary",
                      footerClass:
                        "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                      headerClass: "p-2",
                      titleClass:
                        "d-flex align-items-center justify-content-center text-primary w-100",
                      headerBgVariant: "white",
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(async (isConfirmed) => {
                      if (isConfirmed) {
                        await this.$nextTick(() => {
                          this.$refs.concessoesDatatable.refresh();
                        });
                      }
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
      this.selectedAction = "Ações";
    },
    async depositExecutedRequest() {
      this.selectedRowsIds.map(
        async (id) => await this.$store.dispatch("concession/makeDepositClient", id)
      );
    },
    async markDepositAsExecuted() {
      try {
        if (this.selectedRowsIds.length > 1) {
          await this.$bvModal
            .msgBoxConfirm(
              `Você deseja comunicar que o depósito referente à todas as concessões selecionadas foi realizado?\n\nSelecionadas: ${this.selectedRows.length}`,
              {
                title: "Informar 2º depósito realizado",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Sim",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                await this.depositExecutedRequest().then(async (response) => {
                  await this.$bvModal
                    .msgBoxOk("Informe de 2° depósito realizado com sucesso!", {
                      title: "Informar 2º depósito realizado",
                      size: "sm",
                      buttonSize: "md",
                      okVariant: "primary",
                      footerClass:
                        "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                      headerClass: "p-2",
                      titleClass:
                        "d-flex align-items-center justify-content-center text-primary w-100",
                      headerBgVariant: "white",
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(async (isConfirmed) => {
                      if (isConfirmed) {
                        await this.$nextTick(() => {
                          this.$refs.concessoesDatatable.refresh();
                        });
                      }
                    });
                });
              }
              this.selectedAction = "Ações";
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          await this.$bvModal
            .msgBoxConfirm(
              "Você deseja comunicar que o depósito referente à concessão selecionada foi realizado?",
              {
                title: "Informar 2º depósito realizado",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Sim",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                await this.depositExecutedRequest().then(async (response) => {
                  await this.$bvModal
                    .msgBoxOk("Informe de 2° depósito realizado com sucesso!", {
                      title: "Informar 2º depósito realizado",
                      size: "sm",
                      buttonSize: "md",
                      okVariant: "primary",
                      footerClass:
                        "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                      headerClass: "p-2",
                      titleClass:
                        "d-flex align-items-center justify-content-center text-primary w-100",
                      headerBgVariant: "white",
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(async (isConfirmed) => {
                      if (isConfirmed) {
                        await this.$nextTick(() => {
                          this.$refs.concessoesDatatable.refresh();
                        });
                      }
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
      this.selectedAction = "Ações";
    },
    goToLiquidationPage() {
      if (this.selectedRowsIds.length > 1) {
        this.selectedAction = "Ações";
        return this.$swal.fire("Erro", "Selecione apenas um item.", "error");
      }
      this.$router.push({
        name: "admin-concessions.liquidation",
        params: {
          id: this.selectedRowsIds[0],
        },
      });
    },
    async callNcSetDue() {
        if (this.selectedRowsIds.length > 1) {
          this.selectedAction = "Ação";
          return this.$swal.fire("Erro", "Selecione apenas um item.", "error");
        }

        try {
          await this.$bvModal
            .msgBoxConfirm(
              "Você deseja marcar esta Nota Comercial como vencida?",
              {
                title: "Marcar Nota Comercial como vencida",
                size: "sm",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "Sim",
                cancelVariant: "outline-primary",
                cancelTitle: "Cancelar",
                footerClass:
                  "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                headerClass: "p-2",
                titleClass:
                  "d-flex align-items-center justify-content-center text-primary w-100",
                headerBgVariant: "white",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                await this.ncSetDueRequest().then(async (response) => {
                  await this.$bvModal
                    .msgBoxOk("Nota Comercial foi marcada como vencida com sucesso!", {
                      title: "Nota Comercial marcada como vencida",
                      size: "sm",
                      buttonSize: "md",
                      okVariant: "primary",
                      footerClass:
                        "d-flex align-items-center justify-content-center text-center border-top-0 p-2",
                      headerClass: "p-2",
                      titleClass:
                        "d-flex align-items-center justify-content-center text-primary w-100",
                      headerBgVariant: "white",
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(async (isConfirmed) => {
                      if (isConfirmed) {
                        this.$nextTick(() => {
                          this.selectedAction = "Ações";
                          this.$refs.concessoesDatatable.refresh();
                        });
                      }
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
      } catch (error) {
        console.log(error);
      }
      this.selectedAction = "Ações";
    },
    async generateXlsxReport() {
      try {
        await this.$swal.showLoading()
        const payload = { path: this.$route.meta.indexObject.fileExportUrl, data: this.selectedRowsIds }
        const { data } = await this.$store.dispatch('common/exportToXlsx', payload)
        downloadURI(data);
        this.$swal.close()
      } catch (error) {
        console.log(error)
      }

    },
    performSelectedAction() {
      if (this.selectedAction) {
        switch (this.selectedAction.label) {
          case "Baixar CNAB":
            this.downloadCnabFile();
            break;
          case "Informar 1º depósito":
            this.markFirstDeposit();
            break;
          case "Informar 2º depósito":
            this.markDepositAsExecuted();
            break;
          case "Liquidar NC":
            this.goToLiquidationPage();
            break;
          case "Marcar NC como Vencida":
            this.callNcSetDue();
            break;
        }
    }
  },
  },
  computed: {
    actionsForSelectedItems() {
      let actions = [
        {
          label: "Baixar CNAB",
          icon: "DownloadIcon",
          classes: "text-muted",
          status: ["aguardando_transferencia"],
        },
        {
          label: "Informar 1º depósito",
          icon: "DownloadIcon",
          classes: "text-muted",
          status: ["aguardando_transferencia"],
        },
        {
          label: "Informar 2º depósito",
          icon: "DownloadIcon",
          classes: "text-muted",
          status: ["depositada"],
        },
        {
          label: "Liquidar NC",
          icon: "DownloadIcon",
          classes: "text-muted",
          status: ["executada", "vencida"],
        },
        {
          label: "Marcar NC como Vencida",
          icon: "CheckSquareIcon",
          classes: "text-muted",
          status: ["executada", "depositada"],
        }
      ];

      actions = actions.filter((action) => {
        return this.uniqStatuses.some((status) => action.status.includes(status));
      });

      if (this.uniqStatuses.length === 1) {
        actions.filter((action) => {
          action.classes = "text-primary cursor-pointer";
        });
      }

      return actions;
    },
    uniqStatuses() {
      let uniqStatus = this.selectedRows.map((r) => r.status);
      uniqStatus = [...new Set(uniqStatus)];
      return uniqStatus;
    },
    showTooltip() {
      return this.uniqStatuses.length > 1;
    },
    selectedRowsIds() {
      return this.selectedRows.map((row) => row.id);
    },
  },
};
</script>

<style scoped>

.custom-selector {
  min-width: 250px;
}
</style>
